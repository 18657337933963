  .item_detail table{
    width:100%;
    border-top:2px solid #a0917c;
    margin: 30px 0;
  }
  .item_detail table tr{
    border-bottom: 1px dashed #a0917c;
  }
  .item_detail table th{
    width:20%;
    padding: 10px 20px;
  }
  .item_detail table td{
    width:80%;
    padding: 10px 20px;
    text-align: left;
  }
  .item_component table{
    width:100%;
    border-left:1px solid #a0917c;
    border-top: 1px solid #a0917c;
    margin: 30px 0;
  }
  .item_component table th{
    border-bottom: 1px dashed #a0917c;
    border-right: 1px dashed #a0917c;
    width:20%;
    padding: 10px 20px;
    text-align: center;
  }
  .item_component table td{
    text-align: center;
  }

/*瀬川*/

.sgw-content{
  justify-content:top;
}


.sgw-img-p{
padding-top:10px;
}

.main_title02.sgw-titleline::before{
top:0px !important;
}

img.absL{
top:-48px !important; 
}


/*冷凍弁当*/

.bento_Imgsize{
  width:300px;
  flex-shrink: 0; /* 追加 */
  margin:0 auto;
}

@media (max-width: 767px){
  .bento_Imgsize{
     width:100%;
  }
 
}
